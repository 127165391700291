@import 'libs/styles/fonts';
@import 'libs/styles/responsive';

.passiv-table {
  .container {
    margin: 0;

    @include target('xl', 'xxl') {
      margin: 0 auto;
    }
  }

  .section--left {
    display: flex;
    justify-content: flex-end;

    &+.section--left {
      margin-top: 1em;
    }
  }

  .section--left__content {
    width: 66.18%;
  }

  .hidden-column {
    display: none;
  }

  .anlagemodelle--table {
    width: 66.18%;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    background-color: transparent;
    border-spacing: inherit;
    padding: inherit;

    &.anlagemodelle--table--fullwidth {
      width: 100%;
    }

    tbody {
      flex-grow: 1;
    }

    tr:after,
    td:after {
      display: none;
    }

    @include target(xs, lg) {
      width: 100%;

      tr,
      td {
        border-bottom: none;
      }
    }

    @include target('xs', 'lg') {
      display: grid;
      grid-template-columns: 1fr;
    }

    .header--cells {
      padding-left: 10px;
      padding-bottom: 13px;
      font-weight: $fw-medium;
      font-size: 20px;
      line-height: 25px;

      @include target(xs, lg) {
        padding-bottom: 0px;
        padding-left: 20px;
      }
    }

    .row {
      border-top: 1px solid;
      min-height: 60px;


      @include target(xs, lg) {
        &.row--header {
          display: none;
        }
      }

      @include target(xs, lg) {
        display: grid;
      }
    }

    .cells {
      text-align: left;
      vertical-align: top;
      padding-top: 3px;
      padding-bottom: 20px;
      min-height: 60px;

      @include target(xs, lg) {
        min-height: 30px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
      }

    }

    @include target('xs', 'lg') {
      vertical-align: middle;
      padding-bottom: 0;
      height: 100%;
      align-items: center;
      display: flex;
    }

    .title--cell {
      background-color: $color-red-light;
      width: 20%;

      @include target('xs', 'lg') {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        border-top: 1px;
        padding-bottom: 0px;
        padding-top: 0px;
        display: flex;
        align-items: center;
      }
    }

    .data--cells {
      padding-left: 10px;
      width: calc((100% - 20%) / 3);

      @include target('xs', 'lg') {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-top: 20px;
      }
    }

    ul {
      list-style-type: disc;
      margin-left: 1em;
      margin-bottom: 1em;
    }

    .column-hide {
      display: "none";
    }
  }

  .open-overlay-button {
    display: flex;
    color: $color-white;
    align-items: center;

    p {
      margin-left: 1rem;
    }
  }
}

@include target(xs, lg) {
  .anlagemodelle--table {
    @for $i from 0 through 6 {
      &[data-active-cell="#{$i}"] {
        tr td.data--cells {
          display: none;
        }

        tr td.data--cells:nth-child(#{$i + 2}) {
          // First two aren't actual content, therefore increase by 2
          display: block;
        }
      }
    }
  }
}

.passiv-table__additions {
  p {
    color: #5f5f5f;
    margin-bottom: 1rem;
    font-size: 12px;
    line-height: 1.16;
  }

  @include target(xl, xxl) {
    padding-left: 34%;
    margin-top: 1rem;
  }

  .anlagemodelle--table--fullwidth+& {
    padding-left: 0;
  }
}