@import 'app/styles/config';
@import 'libs/styles/responsive';

.page-title-block {
  color: $color-white;
  background-color: $color-white;
  margin-top: 5.219rem;
  display: flex;
  flex-direction: column;

  @include target(xs, lg) {
    margin-top: 120px;
  }

  @include target('xl', 'xxl') {
    margin-top: 240px;
  }

  .header--product & {
    background: transparent;
  }

  .page-title-block__headline-wrapper {
    background-color: $color-green-dim;
    padding: 120px 1.25rem 1.25rem;

    &.page-title-block__headline-wrapper--no-article {
      height: 100%;
    }

    &.page-title-block__headline-wrapper--black {
      background-color: $color-black;
    }

    &.page-title-block__headline-wrapper--red {
      background-color: $color-red-dim;
    }

    &+.container {
      @include target(xs, lg) {
        background-color: $color-green-dim;
      }
    }
  }

  .container {
    height: 100%;
  }

  .section {
    background-color: $color-green-dim;
    margin-top: 0;
    padding: 0 1.25rem 1.25rem;
    height: 100%;

    @include target('xl', 'xxl') {
      padding-bottom: 0;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    .article {
      margin-top: 0;
    }
  }

  @include target('xl', 'xxl') {

    .page-title-block__headline-wrapper {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 0;
      min-height: 18.625rem;

      .container {
        height: fit-content;
        margin-top: auto;
        max-width: $container-width;
        padding: 0 2.5rem;
      }

      &.page-title-block__headline-wrapper--no-article {
        height: 18.625rem;
      }
    }

    .headline1 {
      width: 60%;
      margin-bottom: 0;

      &.headline1--w85 {
        width: 85%;
      }
    }

    .page-title-block__headline-wrapper--wide {
      @include target(xl, xxl) {
        min-height: 18.625rem;
        height: auto !important;
      }

      .headline1 {
        width: 80%;
        margin-bottom: 0;
      }
    }

    .section {
      background-color: transparent;
      color: $color-black;
      margin-top: 3.75rem;
      padding-top: 0;
    }
  }
}