@import 'app/styles/fonts';
@import 'libs/styles/responsive';

*,
*::before,
*::after {
  @include nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 100%;
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body {
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: $font-SuisseIntl, sans-serif;
  font-style: normal;
}

.no-scroll {
  overflow: hidden;
}

main {
  margin-bottom: 70px !important;
  padding: 0 1.25rem;

  @include target('xl', 'xxl') {
    margin-bottom: 8.75rem !important;
    padding: 0 2.5rem;
  }
}

.hidden {
  display: none !important;
}

.container {
  max-width: $container-width;
  margin: 0 auto;

  @include target('xl', 'xxl') {
    width: 100%;
  }

  @include target('xs', 'sm') {
    margin: 0;
  }

  @include target('md', 'xl') {
    margin: 0;
  }
}

.link-wrapper {
  margin-top: 0.75rem;

  @include target('xl', 'xxl') {
    margin-top: 1rem;
  }
}

.link {
  color: $color-green-dark;
  text-underline-offset: 3px;
  transition: color 0.25s ease;
  padding: 0;
  border: none;
  font-family: inherit;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: $color-green-dark-hover;
  }

  [data-color="red"] & {
    color: $color-red-05;

    &:hover {
      color: $color-red-dim;
    }
  }
}

.link-white {
  color: $color-white;
  text-underline-offset: 3px;
}

.link-black {
  color: $color-black;
  text-underline-offset: 3px;
}

.link--underline {
  font-weight: $fw-medium;
  text-decoration: underline;
  font-size: 0.875rem;

  @include target(xl, xxl) {
    font-size: 1rem;
  }
}

.link--external {
  font-weight: $fw-medium;
  font-size: 1rem;

  &::after {
    content: '';
    background-image: url('/static/media/external_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.5rem;
  }
}

.link-inherit {
  color: inherit;
  text-decoration: none;
}

a {
  color: inherit;
}

p a {
  @extend .link;
  text-decoration: none;
}

p u {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: $color-green-dark;

  &:hover {
    text-decoration-color: $color-green-dark-hover;
  }

  a {
    font-size: 16px;
  }
}

button {
  padding: 0;
}

.strong {
  font-weight: $fw-bold;
}

sub {
  bottom: -0.25em;
  font-size: 75%;
  position: relative;
}

.headline1 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: $fw-bold;
}

.text--primary {
  line-height: 1.25;
  font-size: 16px;
  font-weight: $fw-regular;

  @include target('xl', 'xxl') {
    font-size: 20px;
    line-height: 1.3;
    font-weight: $fw-medium;
  }
}

.text--regular {
  font-weight: $fw-regular;
}

.text--medium {
  font-weight: $fw-medium;
}

.text--outlined {
  border-left: 3px solid $color-black;
  padding-left: 6px;
}

.text--white {
  color: $color-white;
}

.text--small {
  font-size: 14px;
  line-height: 1.29;
}

.text--grey-dark {
  color: $color-grey-dark;
}

.text--blue-dimmer {
  color: $color-blue-dimmer;
}

.text--disclaimer {
  margin-top: 0.875rem;
  color: $color-grey;
}

.mark {
  background: transparent;
  color: $color-green-dark;
}

.hyphens {
  hyphens: auto;
}

.headline1 {
  font-size: 32px;
  line-height: 1;
  font-weight: $fw-medium;

  @include target('xl', 'xxl') {
    font-size: 64px;
    line-height: 1;
  }
}

.headline2 {
  font-size: 20px;
  line-height: 1.3;

  @include target('xl', 'xxl') {
    font-size: 48px;
    line-height: 1;
  }
}

.headline3 {
  font-size: 20px;
  line-height: 1.3;

  @include target('xl', 'xxl') {
    font-size: 36px;
    line-height: 1;
  }
}

@mixin by-tags {
  h1 {
    @extend .headline1;
  }

  h2 {
    @extend .headline2;
  }

  h3 {
    @extend .headline3;
  }

  p {
    @extend .text--primary;
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 0
    }
  }

  strong {
    @extend .strong;
  }

  mark {
    @extend .mark;
  }

  em {
    font-style: italic;
  }
}

#gtx-trans,
.gtx-trans-icon {
  display: none;
}

// djangocms_text_ckeditor plugin wrapper class
.text-plugin-content,
.cke_editable {
  @include by-tags;

  ul {
    color: $color-black;
    letter-spacing: 0.3px;
    line-height: 1.333;
    margin-bottom: 24px;
    padding-left: 28px;
    list-style: disc outside;

    li {
      padding-left: 12px;
      position: relative;
    }
  }

  li a {
    @extend .link;
    text-decoration: none;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 6px;

      @include target(xl, xxl) {
        margin-bottom: 12px;
      }
    }

    img {
      max-width: 100%;
    }
  }

  p {
    margin-bottom: 60px;
    font-size: 1.125rem;
    font-weight: $fw-regular;
    font-family: $font-Tiempos;

    @include target('xs', 'sm') {
      margin-bottom: 50px;
    }
  }

  p.quote {
    font-family: $font-Tiempos;
    font-size: 1.5rem;
    font-weight: $fw-medium;
    line-height: 1.2;
    letter-spacing: 0;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;

    @include target(md, xxl) {
      font-size: 2.25rem;
    }

    &+p.quote-name {
      margin-top: -52px;
    }
  }

  p.quote-name {
    text-align: center;
    font-weight: $fw-regular;
    margin-bottom: 60px;
    color: $color-grey-dark;
    font-size: 1.125rem;
    line-height: 1.222;
  }

  p.lead {
    color: $color-black;
    font-family: $font-Tiempos;
    font-size: 1.25rem;
    font-weight: $fw-regular;
    line-height: 1.17;
    letter-spacing: -0.005px;
    margin-bottom: 60px;

    @include target(md, xxl) {
      font-size: 1.5rem;
    }
  }

  p.disclaimer {
    margin-top: 60px;
    font-family: $font-SuisseIntl;
    font-size: 1rem !important;
    font-weight: $fw-regular;
    color: $color-grey-dark;
  }

  p.interview-question {
    margin-top: 60px;
    font-weight: $fw-bold;
  }
}

ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 32px;
  margin-bottom: 24px;

  li {
    &::marker {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
    }

    img {
      margin-top: 1em;

      @include target(md, xxl) {
        max-width: 80% !important;
      }
    }
  }
}

@mixin blog-content {
  // Overrides for blog specific content, added by text plugin.

  ol,
  ul {
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 0.75rem;
    margin-top: 0;
  }

  figure {
    margin-top: 2.5rem;
    margin-bottom: 3.75rem;

    img {
      display: block;
    }
  }

  figcaption {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    font-family: $font-SuisseIntl;
  }

  h1,
  h2 {
    font-family: $font-Tiempos;
    font-size: 1.5rem;
    font-weight: $fw-regular;
    line-height: 1.17;
    letter-spacing: 0;
    margin-bottom: 1rem;
    margin-top: 3.75rem;
    color: $color-grey;

    strong {
      font-weight: $fw-regular;
    }
  }

  h3 {
    font-family: $font-Tiempos;
    font-size: 1.125rem;
    font-weight: $fw-semi-bold;
    line-height: 1.375;
    letter-spacing: 0;
    margin-top: 2.75rem;
    margin-bottom: 0.75rem;
  }

  h4 {
    font-weight: $fw-regular;
    font-size: 1.125rem;
    text-decoration: underline;
    text-decoration-thickness: from-font;
    text-underline-offset: 2px;
    margin-bottom: 8px;
    margin-top: 40px;

    strong {
      font-weight: inherit;
    }
  }
}

.text-plugin-content--blog {
  @include blog-content;
}