@use 'libs/styles/responsive';

.award-section-title {
    font-weight: 500 !important;
    margin-top: 60px;
}

.award-section-logo-container {
    display: flex;
    flex-direction: row;
    gap: 60px;
    margin-top: 35px;
    flex-wrap: wrap;

    .award-section-logo {
        margin-top: unset;
    }

    @include responsive.target(xs) {
        gap: 35px;
        row-gap: 20px;

        .award-section-logo {
            max-height: 105px;
        }
    }
}

.award-section-logo-container__break {
    @include responsive.target(xs) {
        flex-basis: 100%;
    }

    @include responsive.target(sm, xxl) {
        display: none;
    }
}

.award-section-logo {
    display: flex;
    text-align: center;
    margin-top: 35px;
    width: 210px;
    max-width: calc(50% - (35px / 2));
    max-height: 150px;
    aspect-ratio: 3 / 2;

    &.award-section-logo--large {
        width: 320px;
        max-width: 85%;
        aspect-ratio: unset;

        img {
            margin: 0;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
}