@import 'app/styles/config';
@import 'libs/styles/responsive';

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1em;

  @include target('md', 'xxl') {
    gap: 2rem;
  }

  @for $i from 0 through 3 {
    &+p>.product-id-#{$i} {
      display: none;
    }

    &[data-product-id="#{$i}"] {
      &+p>.product-id-#{$i} {
        display: inline;
      }
    }
  }

  .table__select {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      color: $color-black;
      font-weight: $fw-medium;
      margin-bottom: 0.5rem;
    }

    .table__select__wrapper {
      inset: 0;
      position: relative;
      width: 100%;
    }

    .table__select__outside {
      position: fixed;
      inset: 0;
      z-index: 5;
      width: 100vw;
      height: 100vh;
    }

    .table__select__element {
      cursor: pointer;
      width: 100%;
      border-left: 3px solid $color-black;
      padding: 1.25rem 1rem;
      font-size: 1rem;
      line-height: 1.25em;
      color: $color-black;
      background-color: $color-grey-lighter;
      font-family: $font-SuisseIntl;
    }

    .table__select__options {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translateY(100%);
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.08);

      .table__select__options__option {
        cursor: pointer;
        transition: background-color 0.1s ease-out;
        padding: 1.125rem 1.25rem;
        font-size: 1rem;

        &:hover {
          background-color: $color-grey-lighter;
        }
      }

      .table__select__options__option--active {
        background-color: $color-grey-light;
      }
    }

    .table__select__arrow {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $color-grey-dark;
      pointer-events: none;
    }
  }

  .table__tables {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @include target('md', 'xxl') {
      margin-top: 0;
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-top: 1px solid $color-black;

      .row__name {
        font-size: 1rem;
        line-height: 1.25em;
        color: $color-black;
        font-weight: $fw-medium;
        flex-basis: 25%;
        max-width: 25%;
        padding: 0.25rem 0 0 0.25rem;
        background-color: $color-blue-bright-bg;
        margin-right: 8px;

        @include target('md', 'xxl') {
          padding-left: 0;
          margin-right: 0;
        }
      }

      .row__container {
        display: flex;
        flex-direction: column;
        flex-basis: 75%;
        max-width: 75%;

        @include target('md', 'xxl') {
          flex-direction: row;
        }

        .row__value {
          padding: 0.25rem 0 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          line-height: 1.25em;

          &:not(:last-child) {
            border-bottom: 1px solid $color-grey;
          }

          label {
            position: relative;

            @include target(xl, xxl) {
              font-weight: $fw-medium;
            }

            .tooltip {
              position: absolute;
              top: auto;
              left: auto;
              right: auto;
              bottom: -2px;
            }
          }

          span {
            font-weight: $fw-regular;
          }

          &.row__value--mobile-bold {
            @include target(xs, sm) {

              label,
              span {
                font-weight: $fw-medium;
              }
            }
          }

          @include target('md', 'xxl') {
            width: 100%;
            justify-content: flex-end;
            padding: 0.25rem 0 1.25rem 0;

            &:not(:last-child) {
              border: none;
            }

            label {
              display: none;
            }

            span {
              text-align: right;
            }
          }
        }
      }
    }

    .labels {
      @include target('xs', 'sm') {
        display: none;
      }

      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      label {
        font-size: 1.25rem;
        line-height: 1.625rem;
        font-weight: $fw-medium;
        text-align: right;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;

        &:first-child {
          justify-content: flex-start;
          flex-basis: 25%;
          flex-shrink: 0;
        }
      }
    }
  }
}

.hide-desktop {
  @include target('md', 'xxl') {
    display: none !important;
  }
}